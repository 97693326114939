
input:focus, input.form-control:focus {

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}


.rs-modal-content{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3px !important;
}

.rs-modal-wrapper .rs-modal-sm{
  width: 420px;
}
